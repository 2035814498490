// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

// const base = (services.data.find(x => x.code == 'offers')).link
const base =
  "https://ap-southeast-1.aws.data.mongodb-api.com/app/contrato-srvc-kcxltrc/endpoint";

// -----------------

export const AssetsOffersCreate = async (item) => {
  const basx = base + "/assets/offer/create/individual";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsOffersList = async (item) => {
  const basx = base + "/assets/offer/list/individual";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsCreatorsOffersAll = async (item) => {
  const basx = base + "/assets/creators/offers/all";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};


  export const AssetsCreatorsOffersList = async (item) => {
    const basx = base + "/assets/creators/offers";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };


  export const AssetsOffersDetails = async (item) => {
    const basx = base + "/assets/offer/details/individual";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const AssetsOffersStatusSet = async (item) => {
    const basx = base + "/assets/individual/offers/status/set";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
};
  
 export const AssetsOffersEdit = async (item) => {
   const basx = base + "/asset/individual/offer/edit";
   const head = {
     "Content-Type": "application/json",
     Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
   };
   const datx = {
     data: item.data,
     srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
   };

   const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

   var result;
   try {
     result = await axios.post(basx, { payload }, { headers: head });
     const data = await decrypt(
       result.data.data,
       process.env.REACT_APP_WEBB_SITE_SCRT
     );
     return { code: result.status, stat: result.data.stat, data };
   } catch (error) {
     // console.log(error)
     return { code: error.response.status, ...error.response.data };
   }
 };



  export const AssetsOffersAccept = async (item) => {
    const basx = base + "/assets/offers/individual/accept";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };



















  export const AssetsOffersListResale = async (item) => {
    const basx = base + "/assets/offers/list";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };

  export const AssetsOffersResaleDetails = async (item) => {
    const basx = base + "/assets/offer/details";
    const head = {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
    };
    const datx = {
      data: item.data,
      srvc: process.env.REACT_APP_WEBB_SITE_SRVC,
    };

    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

    var result;
    try {
      result = await axios.post(basx, { payload }, { headers: head });
      const data = await decrypt(
        result.data.data,
        process.env.REACT_APP_WEBB_SITE_SCRT
      );
      return { code: result.status, stat: result.data.stat, data };
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error.response.data };
    }
  };
  
export const CreateIndividualOffers = async (item) => {
  const basx = base + "/assets/offer/create/individual";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsUsersOffersList = async (item) => {
  const basx = base + "/assets/users/offers/list";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetsUsersOfferCancel = async (item) => {
  const basx = base + "/asset/offer/cancel";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUserOfferEdit = async (item) => {
  const basx = base + "/asset/user/offer/edit";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };

  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};