// content
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import { toBase64 } from "../../services/srvc-utilities";

import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";
import { AssetsTransferCreate } from "../../services/srvc-transfers-assets-realm";
import { TokensDetails } from "../../services/srvc-tokens-realm";
import {
  AssetsCreatorsOffersList,
  AssetsOffersCreate,
} from "../../services/srvc-offers-realm";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";
import { DocumentCreate } from "../../services/srvc-media-docs-realm";

import media from "../../data.media/contrato-card.png";

export default function AssetsOwnedDetailsUserModule() {
  const { id } = useParams();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const [submit, setSubmit] = useState(false);

  const [data, setData] = useState();
  const [balance, setBalance] = useState({ number: "", ticker: "STX" });

  const [showModal, setShowModal] = useState(false);

  const [offers, setOffers] = useState([]);
  const [base64, setBase64] = useState("");
  const [link, setLink] = useState();
  const [form, setForm] = useState(false);

  const [refresh, setRefesh] = useState(false);

  const [sale, setSale] = useState("");
  const [rate, setRate] = useState("");
  const [user, setUser] = useState("");

  const [memo, setMemo] = useState("");
  const [offerMemo, setOfferMemo] = useState("");
  const [unit, setUnit] = useState({ status: { sale: false } });

  useEffect(() => {
    setLoader(true);
    if (asset) {
      const fetchData = async () => {
        var res = await TokensDetails({ data: { item: id }, srvc: "" });
        if (res.stat) {
          setData(res.data);
          var bal =
            res.data.balance.mint -
            res.data.balance.sale -
            res.data.balance.burn;
          setBalance({ number: bal, ticker: "STX" });
        }
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    if (asset && data?.unit?.item) {
      const fetchData = async () => {
        var result = await AssetsCreatorsOffersList({
          data: { user: asset.item, unit: data?.unit?.item },
        });
        if (result.stat) {
          console.log(result.data.list)
          setOffers(result.data.list);
          setLoader(false);
        }
      };
      fetchData();
    }
  }, [refresh, data?.unit?.item]);

  useEffect(() => {
    setForm(false);
    if (base64 && base64 !== "") setForm(true);
  }, [base64]);

  const SetFileLink = async (link) => {
    // console.log (link);
    setLink(link);
    // handleChange("link", link);
  };

  const SetFileData = async (data) => {
    var str1 = await toBase64(data);
    var str2 = str1.split("base64");
    var b64 = str2[1].split(",");
    //  console.log(b64[1])
    setBase64(str1);
    //handleChange("file", base64);
  };

  const ResetValues = async () => {
    setRate("");
    setSale("");
    setOfferMemo("");
    setUser("");
    setMemo("");
    setBase64("");
  };

  const handleSubmit = async () => {
    setMemo("Please Wait ...");
    setSubmit(true);
    setShowModal(true); // Show the modal
    var datax = {
      user: { mail: user },
      unit: { item: data.unit.item },
      sale: { number: sale, ticker: "STX" },
      rate: { number: rate, ticker: "INR" },
      memo: offerMemo,
      debit: {
        name: asset.name,
        mail: asset.mail,
        item: asset.item,
      },
    };

    var res = await AssetsOffersCreate({ data: datax, srvc: "" });
    if (res.stat) {
      // setMemo("Please wait.");
      var datx = {
        user: { name: asset.name, mail: asset.mail, item: asset.item },
        name: "Request to share transfer",
        number: "24" + Date.now().toString(),
        format: "others",
        offer: res?.data?.item,
        unit: { item: data.unit.item },
        sort: "file",
        file: base64,
      };

      const result = await DocumentCreate({ data: datx, srvc: "" });
      if (result.stat) {
        setMemo("Offer Created successfully.");
        setTimeout(() => {
          ResetValues();
          setSubmit(false);
          setRefesh(!refresh);
          const modal = document.querySelector('[data-bs-dismiss="modal"]'); // Select the modal
          if (modal) {
            modal.click(); // Trigger the close action
          }
        }, 1000);
      }
    } else {
      setMemo(res.memo);
    }
  };

  if (loader) {
    return <div className="mx-3"> Please Wait... </div>;
  }

  return (
    <>
      <div className="mx-3">
        <div className="p-3 back-color-wite rounded-xd">
          <p className="text-lead text-bold m-0">
            {data?.unit?.name || "******"}
          </p>
          <p className="text-normal m-0">{data?.unit?.number || "******"}</p>

          <WebbDividerMedium />
          <WebbDividerMedium />
          <p className="m-0">Balance</p>
          <p className="m-0">
            <span className="text-header-sm text-bold">
              {NumberFormat(balance.number, "w")}
            </span>
            <span className="ms-1">{balance.ticker}</span>
          </p>

          {/* transfer */}
          <div className="">
            <WebbDividerMedium />
            <button
              onClick={() => navigate(`/user/asset/transfer/${id}`)}
              className="btn btn-primary text-small rounded-xx w-auto"
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal1"
              disabled={balance.number == 0}
            >
              Create Transfer
            </button>
          </div>
        </div>
      </div>

      {/* offers */}
      <WebbDividerSmall />
      <div className="mx-3">
        <div className=" back-color-wite rounded-xd">
          <p className="text-normal text-bold m-0 p-3 border-bottom">
            Created Offers
          </p>

          <div className={offers.length <= 0 ? "" : "d-none"}>
            No Active Offers
          </div>

          <div
            className={offers.length > 0 ? "p-3 pt-0" : "d-none p-3 pt-0"}
            style={{ maxHeight: "430px", overflowY: "scroll" }}
          >
            <WebbDividerSmall />
            {offers.length > 0 &&
              offers.map((item, x) => (
                <div className="" key={x}>
                  <div className="d-flex justify-content-between align-items-center w-100 mb-2 mt-2">
                    <div className="">
                      <p className="text-bold m-0">
                        {"For: "}
                        {item?.user?.name || "******"}
                      </p>
                      <p className="m-0">{item?.user?.mail || "******"}</p>
                      <p className="m-0">
                        {" "}
                        {item?.meta?.memo
                          ? item.meta.memo.length > 50
                            ? `${item.meta.memo.slice(0, 50)}...`
                            : item.meta.memo
                          : "******"}
                      </p>
                      <p className="m-0">
                        {item?.sale?.nmbr || "*****"}
                        {" shares"} {" @ "} {item?.rate.tick} {item?.rate?.nmbr}
                      </p>
                      <p className="m-0 d-none">
                        {"Created: "}
                        {new Date(item.created).toLocaleString()}
                      </p>
                    </div>
                    <div className="text-end">
                      {item?.stat == 0 ? (
                        <i className="bx bxs-circle text-warning" />
                      ) : item?.stat == 6 ? (
                        <i className="bx bxs-circle text-success" />
                      ) : (
                        <i className="bx bxs-circle text-danger" />
                      )}
                    </div>
                  </div>
                  <div className="border-bottom"></div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* transfers */}
      <WebbDividerSmall />
      <div className="mx-3 d-none">
        <div className="p-3 back-color-wite rounded-xd">
          <p className="text-normal text-bold m-0">Transfers</p>
        </div>

        <div className={``}></div>
      </div>

      <div className="mx-3">
        {/* if unit.status.sale == false */}

        <div className={unit.status.sale ? "d-flex mt-3" : "d-none"}>
          <div className="me-auto d-none">
            <button className="btn btn-primary text-small rounded-xx" disabled>
              Burn
            </button>
          </div>
          <div className="text-end">
            <button
              className="btn btn-primary  rounded-xx"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              disabled={balance.number == 0}
            >
              Transfer
            </button>
          </div>
        </div>

        {/* modal */}
        <div
          className="modal fade rounded-xd"
          id="exampleModal1"
          tabIndex="-1"
          data-bs-backdrop="static" // Prevent closing when clicking outside the modal
          data-bs-keyboard="false" // Prevent closing with the Escape key
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div className="modal-dialog rounded-xd mt-5">
            <div className="modal-content w-100">
              <div className="modal-header border-none align-middle">
                <p className="text-lead m-0" id="exampleModalLabel">
                  Create Offer
                </p>
                <button
                  onClick={() => ResetValues()}
                  type="button"
                  className="btn-close "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <label className="mb-1">Shares To Transfer</label>
                <input
                  type="text"
                  value={sale}
                  onChange={(e) => {
                    const value = e.target.value.replace(/^0|[^0-9]/g, "");
                    setSale(value);
                  }}
                  placeholder="1234"
                  className="form-control mb-2"
                />
                <label className="mb-1">Rate</label>
                <input
                  type="text"
                  value={rate}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setRate(value);
                  }}
                  placeholder="1234"
                  className="form-control mb-2"
                />
                <label className="mb-1">User (Email)</label>
                <input
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  placeholder="user@gmail.com"
                  className="form-control mb-2"
                />
                <label className="mb-1">Comment</label>
                <textarea
                  value={offerMemo}
                  onChange={(e) => setOfferMemo(e.target.value)}
                  placeholder="Type comment"
                  rows={3}
                  className="form-control mb-2"
                />
                <div className="mb-3">
                  <label className="mb-1">
                    File Attachment (PDF) <span className="text-danger">*</span>
                  </label>
                  <FileSaveFirebase
                    name={id}
                    link={SetFileLink}
                    file={SetFileData}
                  />
                </div>
              </div>

              <p className="text-small mx-3 text-primary mt -3">{memo}</p>

              {!submit && (
                <div className="modal-footer d-flex">
                  <div className="me-auto">
                    {" "}
                    <button
                      onClick={() => ResetValues()}
                      type="button"
                      className="btn btn-secondary rounded-xx text-small"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <div className="text-end">
                    {" "}
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary rounded-xx text-small"
                      disabled={
                        !sale ||
                        !rate ||
                        !user ||
                        !offerMemo ||
                        parseFloat(sale) > parseFloat(balance.number) ||
                        !base64
                      }
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
