import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";
import {
  DocumentCreate,
  OffersDocumentList,
} from "../../services/srvc-media-docs-realm";
import { toBase64 } from "../../services/srvc-utilities";

import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";
import {
  AssetsTransferClaim,
  AssetsTransferCreate,
  AssetsTransferSubmit,
} from "../../services/srvc-transfers-assets-realm";
import {
  AssetsOffersAccept,
  AssetsOffersCreate,
  AssetsOffersDetails,
  AssetsOffersEdit,
  AssetsOffersStatusSet,
} from "../../services/srvc-offers-realm";

export default function TeamOffersDetailsModule() {
  const { id } = useParams();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const [submit, setSubmit] = useState(false);

  const [data, setData] = useState(null);
  const [balance, setBalance] = useState({ number: "", ticker: "STX" });

  const [showModal, setShowModal] = useState(false);

  const [refresh, setRefesh] = useState(false);
  const [decline, setDecline] = useState(false);

  const [sale, setSale] = useState("");
  const [rate, setRate] = useState("");
  const [user, setUser] = useState("");
  const [done, setDone] = useState(false);
  const [memo, setMemo] = useState("");

  const [file1, setfile1] = useState();
  const [file2, setfile2] = useState();
  const [link, setLink] = useState();
  const [form, setForm] = useState(false);
  const [offerDocuments, setOfferDocuments] = useState([]);
  const [disableUI, setDisableUI] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [comment , setComment] = useState("")

  useEffect(() => {
    setLoader(true);
    if (asset && id) {
      const fetchData = async () => {
        var res = await AssetsOffersDetails({ data: { item: id }, srvc: "" });
        var result = await OffersDocumentList({
          data: { offer: id },
          srvc: "",
        });
        if (res.stat) {
          setData(res.data);
          setRate(res?.data?.rate?.rate?.nmbr);
          setSale(res?.data?.sale?.nmbr);
        }
        if (result.stat) {
          setOfferDocuments(result.data.list);
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);

  const handleEdit = async () => {
    // setLoader(true)
    setMemo("Please Wait ...");
    setSubmit(true);
    var datax = {
      item: id,
      rate: rate,
      sale: sale,
    };

    var res = await AssetsOffersEdit({ data: datax, srvc: "" });
    //console.log(res)
    if (res.stat) {
      setMemo("");
      setRefesh(!refresh);
      ResetValues();
      setLoader(false);
    } else {
      setMemo(res.memo);
    }
  };

  const handleDecline = async () => {
    setMemo("Please Wait ...");
    setSubmit(true);
    var datax = {
      item: id,
      status: "4",
    };

    var res = await AssetsOffersStatusSet({ data: datax, srvc: "" });
    //console.log(res)
    if (res.stat) {
      setMemo("Offer declined successfully.");
      setTimeout(() => {
        navigate(`/${usxx}/offers`);
      }, 1000);
    } else {
      setMemo(res.memo);
    }
  };

  useEffect(() => {
    setfile2(null);
  }, [showFile]);

  const SetFileLink = async (link) => {
    // console.log (link);
    setLink(link);
    // handleChange("link", link);
  };

  const handleDownload = async (item) => {
    if (item?.link && item?.link != "") {
      window.open(item.link);
    }
    if (item?.base64) {
      var base64String = item.base64.split(";base64,").pop(1);
      // console.log(base64String)
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };
  const SetFileData1 = async (data) => {
    var str1 = await toBase64(data);
    var str2 = str1.split("base64");
    var b64 = str2[1].split(",");
    //  console.log(b64[1])
    setfile1(str1);
    //handleChange("file", base64);
  };

  const SetFileData2 = async (data) => {
    var str1 = await toBase64(data);
    var str2 = str1.split("base64");
    var b64 = str2[1].split(",");
    //  console.log(b64[1])
    setfile2(str1);
    //handleChange("file", base64);
  };

  const handleAccept = async () => {
    // setLoader(true);
    setMemo("Please wait...");
    setDone(true);
    // setDisableUI(true);

    for (let file of [file1, file2]) {
      var datx = {
        user: { name: asset.name, mail: asset.mail, item: asset.item },
        name: "Aggrement to share transfer",
        number: "24" + Date.now().toString(),
        format: "others",
        offer: res?.data?.item,
        unit: { item: data.unit.item },
        sort: "file",
        file: file,
      };

      var result = await DocumentCreate({ data: datx, srvc: "" });
    }
    if (result.stat) {
      var res = await AssetsOffersStatusSet({
        data: { item: id, status: "1",comment : comment , active: true },
        srvc: "",
      });
      if (res.stat) {
        setMemo("Offer Approved");
        setDone(false);
        setTimeout(() => {
          navigate(`/${usxx}/offers`);
        }, 1000);
        Resetfiles();
      }
    }
  };

  const ResetValues = async () => {
    setRate("");
    setSale("");
  };

  const Resetfiles = async () => {
    setfile1("");
    setfile2("");
  };

  if (loader) {
    return (
      <>
        <div className="d-flex align-items-center mx-2">
          <WebbLoaderSmall /> <span className="ms-3">Plaese Wait..</span>
        </div>
      </>
    );
  }

  if (!loader && !data) {
    return (
      <>
        <h6 className="text-center fw-bold  mt-5">Offer Not Exist</h6>
      </>
    );
  }

  return (
    <div className="">
      <div className=" ">
        <div className="rounded-xd back-color-wite">
          <div className="card border-none back-color-none h-100">
            <div className="card-body border-none back-color-none">
              <h5 className="text-lead text-bold m-0">
                {data?.unit?.name || data?.asset?.name || "Asset Name"}
              </h5>
              <p className="text-normal m-0">
                {data?.unit?.memo || data?.asset?.memo || "Asset Details"}
              </p>

              {/* offer details */}
              <div className="mt-3">
                <p className="text-normal m-0 text-bold">
                  Shares: {data?.sale?.nmbr || "******"}{" "}
                  {data?.sale?.tick || "******"}
                </p>
                <p className="m-0">
                  Rate: {data?.rate?.rate?.nmbr || "******"}{" "}
                  {data?.rate?.rate?.tick || "******"}
                </p>
              </div>

              {/* Creator Information */}
              <div className="mt-3">
                <p className="text-normal m-0">
                  From: {data?.creator?.name || "******"}
                </p>
                <p className="text-normal m-0">
                  To: {data?.user?.name || "******"}
                </p>
              </div>
              <div className="mt-3">
                <p className="text-normal m-0">
                  {"Comment"}
                  {" : "}
                  {data.meta.memo}
                </p>
              </div>
            </div>

            <div
              className={
                data?.active
                  ? "card-footer back-color-wite border-none back-color-none "
                  : "d-none"
              }
            >
              <p className="m-0">
                {"Status"}
                {" : "}
                {data?.active ? "Active" : "Inactive"}
              </p>
              <p className="m-0">
                {"Created"}
                {" : "}
                {new Date(data.created).toLocaleString()}
              </p>
            </div>
            <div className="card-footer back-color-wite border-none back-color-none mb-3">
              <p className="text-normal m-0 mb-1 text-bold">{"Documents :"}</p>
              {offerDocuments.length == 0 ? (
                <p className="text-muted">No Records</p>
              ) : (
                <div className="back-color-wite border rounded-xd">
                  {offerDocuments.map((item, i) => (
                    <div
                      className={
                        i < data.length - 1 ? "border-bottom p-1" : "p-1"
                      }
                      key={i}
                    >
                      {/* <div className="p-1"> */}
                      <div
                        className="p-2 d-flex cursor hilite rounded"
                        onClick={() => handleDownload(item)}
                      >
                        <div className="">
                          <p className="text-normal text-bold m-0 text-success mb-1">
                            {item.meta.name}
                          </p>
                          <p className="m-0">ID: {item?.number || ""}</p>
                          <p className="text-color-tone m-0">
                            {new Date(item.created).toLocaleString()}{" "}
                          </p>
                        </div>

                        <div className="ms-auto">
                          <i
                            class="bi bi-arrow-down-circle text-icon-sm"
                            style={{ fontSize: "" }}
                          ></i>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <p className="mx-3 mb-3">{memo}</p>
          </div>
        </div>
      </div>

      <WebbDividerSmall />
      <div className="d-flex mt-3">
        <div className="me-auto">
          <button
            // onClick={() => handleDecline()}
            className="btn btn-outline-secondary text-small rounded-xx"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal3"
          >
            Decline
          </button>
        </div>
        <div className="text-end">
          <button
            className="btn btn-light text-small rounded-xx"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
          >
            Edit
          </button>
          <button
            className="btn btn-success text-small rounded-xx ms-2"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal2"
            // onClick={() => handleAccept()}
          >
            Approve
          </button>
        </div>
      </div>

      {/* modal */}
      {/* Modal */}
      <div
        className="modal fade rounded-xd"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
        data-bs-backdrop="static" // Prevent closing when clicking outside the modal
        data-bs-keyboard="false" // Prevent closing with the Escape key
        // onShow={() => setModalVisible(true)}
        // onHide={() => setModalVisible(false)}
      >
        <div className="modal-dialog">
          <div className="modal-content w-100">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Offer{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={() => setModalVisible(false)}
                // disabled={disableUI} // Disable close button during countdown
              ></button>
            </div>
            <div className="modal-body">
              <label className="mb-1">Share Count</label>
              <input
                type="text"
                value={sale}
                onChange={(e) => {
                  const value = e.target.value.replace(/^0|[^0-9]/g, "");
                  setSale(value);
                }}
                className="form-control mb-2"
              />
              <label className="mb-1">Rate</label>
              <input
                type="text"
                value={rate}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setRate(value);
                }}
                className="form-control mb-2"
              />
            </div>
            <p className="mx-3 mb-3">{memo}</p>
            {!loader && (
              <div className="modal-footer border-none d-flex">
                <div className="me-auto">
                  {" "}
                  <button
                    // onClick={() => ResetValues()}
                    type="button"
                    className="btn btn-secondary rounded-xx text-small"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-end">
                  {" "}
                  <button
                    type="button"
                    onClick={() => handleEdit()}
                    className="btn btn-success rounded-xx text-small"
                    disabled={!rate}
                  >
                    save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade rounded-xd"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
        data-bs-backdrop="static" // Prevent closing when clicking outside the modal
        data-bs-keyboard="false" // Prevent closing with the Escape key
        // onShow={() => setModalVisible(true)}
        // onHide={() => setModalVisible(false)}
      >
        <div className="modal-dialog">
          <div className="modal-content w-100">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Approval{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={() => setModalVisible(false)}
                // disabled={disableUI} // Disable close button during countdown
              ></button>
            </div>
            <div className="modal-body mb-0">
              <div className="mb-1">
                <label className="mb-1 text-small">Comment</label>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Type comment"
                  rows={3}
                  className="form-control mb-2"
                />
                <label className="mb-1">
                  File Attachment (PDF) <span className="text-danger">*</span>
                </label>
                <FileSaveFirebase
                  name={id}
                  link={SetFileLink}
                  file={SetFileData1}
                />
              </div>
              {showFile ? (
                <div className="mb-1">
                  <label className="mb-1 d-none">
                    File Attachment (PDF) <span className="text-danger">*</span>
                  </label>
                  <FileSaveFirebase
                    name={id}
                    link={SetFileLink}
                    file={SetFileData2}
                  />
                </div>
              ) : (
                ""
              )}
              {!showFile ? (
                <p
                  className="text-primary text-small cursor"
                  onClick={() => setShowFile(true)}
                >
                  Add more file
                </p>
              ) : (
                <p
                  className="text-primary text-small cursor"
                  onClick={() => setShowFile(false)}
                >
                  Remove more file
                </p>
              )}
            </div>
            <p className="mx-3 my-3">{memo}</p>
            {!loader && !done && (
              <div className="modal-footer border-none d-flex">
                <div className="me-auto">
                  {" "}
                  <button
                    // onClick={() => ResetValues()}
                    type="button"
                    className="btn btn-secondary rounded-xx text-small"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-end">
                  {" "}
                  <button
                    type="button"
                    onClick={() => handleAccept()}
                    className="btn btn-success rounded-xx text-small"
                    disabled={!file1}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade rounded-xd"
        id="exampleModal3"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel3"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content w-100">
            <div className="modal-body border-none p-3 top-50">
              <div className="rounded-xd">
                <p className="text-bold p-2 ">
                  Are you sure you want to decline this offer?
                </p>
                <div className="d-flex justify-content-between">
                  <button
                    // onClick={() => setShowRevoke(false)}
                    className="btn rounded-xx btn-outline-secondary back-color-lite text-small float-end mt-2  height-md"
                    type="submit"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => handleDecline()}
                    className="btn rounded-xx btn-success text-small float-end mt-2  height-md"
                    type="submit"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
