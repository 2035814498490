// content
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import { toBase64 } from "../../services/srvc-utilities";

import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";
import { AssetsTransferCreate } from "../../services/srvc-transfers-assets-realm";
import { TokensDetails } from "../../services/srvc-tokens-realm";
import {
  AssetsCreatorsOffersList,
  AssetsOffersCreate,
} from "../../services/srvc-offers-realm";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";
import { DocumentCreate } from "../../services/srvc-media-docs-realm";

import media from "../../data.media/contrato-card.png";
import {
  UserAccountCreate,
  UserAccountDetails,
} from "../../services/srvc-users-realm";

export default function AssetsOwnedTransfersModule() {
  const { id } = useParams();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const [submit, setSubmit] = useState(false);

  const [data, setData] = useState();
  const [balance, setBalance] = useState({ number: "", ticker: "STX" });

  const [showModal, setShowModal] = useState(false);

  const [offers, setOffers] = useState([]);
  const [base64, setBase64] = useState("");
  const [link, setLink] = useState();
  const [form, setForm] = useState(false);

  const [refresh, setRefesh] = useState(false);

  const [sale, setSale] = useState("");
  const [rate, setRate] = useState("");
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mailCheck, setMailCheck] = useState(true);
  const [isUserExist, setIsUserExist] = useState(false);

  const [memo, setMemo] = useState("");
  const [offerMemo, setOfferMemo] = useState("");
  const [unit, setUnit] = useState({ status: { sale: false } });

  useEffect(() => {
    setLoader(true);
    if (asset) {
      const fetchData = async () => {
        var res = await TokensDetails({ data: { item: id }, srvc: "" });
        if (res.stat) {
          setData(res.data);
          var bal =
            res.data.balance.mint -
            res.data.balance.sale -
            res.data.balance.burn;
          setBalance({ number: bal, ticker: "STX" });
        }
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    setForm(false);
    if (base64 && base64 !== "") setForm(true);
  }, [base64]);

  const SetFileLink = async (link) => {
    // console.log (link);
    setLink(link);
    // handleChange("link", link);
  };

  const SetFileData = async (data) => {
    var str1 = await toBase64(data);
    var str2 = str1.split("base64");
    var b64 = str2[1].split(",");
    //  console.log(b64[1])
    setBase64(str1);
    //handleChange("file", base64);
  };

  const ResetValues = async () => {
    setRate("");
    setSale("");
    setOfferMemo("");
    setUser("");
    setMemo("");
    setBase64("");
    setName("");
    setMobile("");
  };

  const handleMailSubmit = async () => {
    var datax = {
      user: user,
    };

    var res = await UserAccountDetails({ data: datax, srvc: "" });
    if (res.stat) {
      // setMemo("Please wait.");
      setName(res?.data?.name);
      setMobile(res?.data?.mobile);
      setMailCheck(false);
      setIsUserExist(true);
    } else {
      // setMemo(res.memo);
      setMailCheck(false);
    }
  };

  const handleSubmit = async () => {
    setMemo("Please Wait ...");
    setSubmit(true);
    var datax = {
      user: { mail: user, name: name, mobile: mobile },
      unit: { item: data.unit.item },
      sale: { number: sale, ticker: "STX" },
      rate: { number: rate, ticker: "INR" },
      memo: offerMemo,
      debit: {
        name: asset.name,
        mail: asset.mail,
        item: asset.item,
      },
    };

    if (!isUserExist) {
      let res = await UserAccountCreate({
        data: {
          mail: user,
          name: name,
          mobile: mobile,
          self: false,
          active: true,
        },
        srvc: "",
      });
      // console.log("Account Created")
    }

    var res = await AssetsOffersCreate({ data: datax, srvc: "" });
    if (res.stat) {
      // setMemo("Please wait.");
      var datx = {
        user: { name: asset.name, mail: asset.mail, item: asset.item },
        name: "Request to share transfer",
        number: "24" + Date.now().toString(),
        format: "others",
        offer: res?.data?.item,
        unit: { item: data.unit.item },
        sort: "file",
        file: base64,
      };

      const result = await DocumentCreate({ data: datx, srvc: "" });
      if (result.stat) {
        setMemo("Offer Created successfully.");
        setTimeout(() => {
          ResetValues();
          setSubmit(false);
         navigate(`/user/asset/owned/${id}`);
        }, 1000);
      }
    } else {
      setMemo(res.memo);
    }
  };

  // if (loader) {
  //   return <div className="mx-3"> Please Wait... </div>;
  // }

  return (
    <>
      {mailCheck ? (
        <div className="mx-3">
          <label className="mb-1 text-small">Email</label>
          <input
            value={user}
            onChange={(e) => setUser(e.target.value)}
            placeholder="user@gmail.com"
            className="form-control mb-2"
          />
          <WebbDividerSmall />
          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              onClick={() => handleMailSubmit()}
              className="btn btn-primary rounded-xx text-small"
              disabled={!user}
            >
              continue
            </button>
          </div>
        </div>
      ) : (
        <div className="mx-3">
          <div className=" rounded-xd ">
            <div className="w-100">
              <div className="modal-body">
                <label className="mb-1 text-small">Name</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                  className="form-control mb-2"
                />
                <label className="mb-1 text-small">User (Email)</label>
                <input
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  placeholder="user@gmail.com"
                  className="form-control mb-2"
                />
                <label className="mb-1 text-small">Mobile</label>
                <input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="7896541230"
                  className="form-control mb-2"
                />
                <label className="mb-1 text-small">Shares To Transfer</label>
                <input
                  type="text"
                  value={sale}
                  onChange={(e) => {
                    const value = e.target.value.replace(/^0|[^0-9]/g, "");
                    setSale(value);
                  }}
                  placeholder="1234"
                  className="form-control mb-2"
                />
                <label className="mb-1 text-small">Rate</label>
                <input
                  type="text"
                  value={rate}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setRate(value);
                  }}
                  placeholder="1234"
                  className="form-control mb-2"
                />
                <label className="mb-1 text-small">Comment</label>
                <textarea
                  value={offerMemo}
                  onChange={(e) => setOfferMemo(e.target.value)}
                  placeholder="Type comment"
                  rows={3}
                  className="form-control mb-2"
                />
                <div className="mb-3">
                  <label className="mb-1 text-small">
                    File Attachment (PDF) <span className="text-danger">*</span>
                  </label>
                  <FileSaveFirebase
                    name={id}
                    link={SetFileLink}
                    file={SetFileData}
                  />
                </div>
              </div>

              <p className="text-small mx-3 text-primary mt -3">{memo}</p>
              <WebbDividerMedium />
              {!submit && (
                <div className="modal-footer d-flex">
                  <div className="me-auto">
                    {" "}
                    <button
                      onClick={() => ResetValues()}
                      type="button"
                      className="btn btn-secondary rounded-xx text-small"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <div className="text-end">
                    {" "}
                    <button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary rounded-xx text-small"
                      disabled={
                        !sale ||
                        !rate ||
                        !user ||
                        !mobile ||
                        !name ||
                        !offerMemo ||
                        parseFloat(sale) > parseFloat(balance.number) ||
                        !base64
                      }
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
