import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { GetUserForm, NumberFormat } from '../../services/srvc-utilities';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local';
import { AssetsOffersList } from '../../services/srvc-offers-realm';
import { useNavigate } from 'react-router-dom';
import WebbDividerSmall from '../webx/webb-divider-sm';

export  const OffersListPendingModule = () => {

const usxx = GetUserForm()
const usrx = GetLocalUser()
const temx = GetLocalBusiness()
const asset = usxx === 'user' ? usrx : temx

const [loader, setLoader] = useState(true);
const [data, setData] = useState([])

const navigate = useNavigate()


useEffect(()=>{
    setLoader(true)
    const fetchdata = async()=>{
        var res = await AssetsOffersList({data:{user:asset.item}})
        if(res.stat ) setData(res.data.list)
        setLoader(false)
    }

    fetchdata()

},[])


if (loader) return <>
    <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>
      
      <span className="align-middle text-lead">
        <i className="bx bxs-info-circle text-color-wait"></i>
      </span>
      <span className='ms-1 text-color-tone'>Please Wait...</span>
      
    </div>
  </>
if (!loader && (!data || data.length === 0)) return <>
<div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>
  
  <span className="align-middle text-lead">
    <i className="bx bxs-info-circle text-color-success"></i>
  </span>
  <span className='ms-1 text-color-tone'>No Pending Offers</span>
  
</div>
</>

  return (
    <div className='back-color-white border rounded-xd'>
    {data && data.map((item, i) => (
      <div key={i}>
        <div className='p-3 d-flex align-items-center cursor'
          onClick={()=> navigate( item.feat.mode == "sale"? `/user/offer/${item.item}` : `/user/resale/offer/${item.item}`) }
        >
          <div className='me-auto'>
            <p className='text-normal text-success text-bold m-0'>{item?.unit?.name}</p> 
            <p className='m-0'>{'From: '}{item?.creator?.name}</p>
            <p className="m-0">
  {item?.meta?.memo ? (item.meta.memo.length > 50 ? `${item.meta.memo.slice(0, 50)}...` : item.meta.memo) : "******"}
</p>
            
            {/* <WebbDividerSmall /> */}
            <p className='text-normal m-0'>
              <span className=''>{ NumberFormat(item?.sale?.nmbr || '',"w")}{' shares'}</span>
              <span className=''> @ </span>
              <span>{item?.rate?.tick} {item?.rate?.nmbr}</span>  
            </p> 
            
          </div>
          <div className='text-end'> 
            <button 
              onClick={()=> navigate( item.feat.mode == "sale"? `/user/offer/${item.item}` : `/user/resale/offer/${item.item}`) } 
              className='btn btn-success rounded-wd text-small d-none d-md-block'
            >View Details</button> 
          </div>
      
        </div>
      
        <div className={i < data.length-1 ? 'border-bottom': ''}></div>
      
      </div>
    ))}
    </div>
  )
}
