// cancel button
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";
import { AssetsDetails } from "../../services/srvc-assets-realm";
import { AssetActivityLog } from "../../services/srvc-track-realm";
import WebbLoaderSmall from "./webb-loader-sm";

export default function AssetActivityLogComponent(props) {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoader(true);
    if (asset) {
      const fetchData = async () => {
        var res = await AssetsDetails({ data: { user: asset.item }, srvc: "" });
        if (res.stat) {
          var res = await AssetActivityLog({ data: { trxn: res.data.item } });
          if (res.stat) {
            setData(res.data.list);
          }
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [asset.item]);

  if (loader) {
    return (
      <div className="text-center">
        {" "}
        <WebbLoaderSmall />{" "}
      </div>
    );
  }

  if (!loader && data.length == 0) {
    return <div className="text-fold">No Activity Found.</div>;
  }

  return (
    <>
      <div className={`border rounded-xd w-100`}>
        {data &&
          data.length > 0 &&
          data.map((item, i) => {
            return (
              <div className="border-bottom p-2" key={item.item}>
                <div className=" px-1 py-2">
                  <p className="text-small m-0 mt-1 text-secondary">
                    {new Date(item.crts).toLocaleDateString()}{" "}
                    {new Date(item.crts).toLocaleTimeString()}
                  </p>
                  <p className="w-100 m-0">{item.memo[0]}</p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
